<template>
  <div class="vld-parent cyb-login error-page base-page">
    <login-form :hasForm="false" pageclass="" layersContainerClass="disconnected-bg">
      <template #text-content>
        <div class="p-d-flex p-flex-column p-ai-start p-jc-center page-width p-d-flex-1">
          <div class="content">
              <div class="base-template">
                <h1>{{ this.$t("notFound.title") }}</h1>
                <h2 class="text-thin">{{ this.$t("notFound.subtitle") }}</h2>
                <p class="error-details">
                  {{ this.$t("notFound.details") }}
                </p>
                <br />
                <Button
                :label="$t('notFound.home')"
                class="p-button p-button-sm p-pl-6 p-pr-6" @click="this.$router.push('/')"></Button>
              </div>
            </div>
        </div>
      </template>
    </login-form>
  </div>
</template>

<script>
import LoginForm from "../components/LoginForm.vue";
export default {
  data() {
    return {};
  },
  components: {
    LoginForm,
  },
};
</script>